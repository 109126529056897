.overSeasContainer {
  width: 100%;
  height: 600px;
  display: flex;
  flex-direction: column;
  background-image: url('../../../../assets/overseasbackground.png');
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: 0 80%;
  position: relative;
}
.overSeasContainer > img {
  width: 100px;
  height: 100px;
  position: absolute;
  bottom: -50px;
  left: -30px;
}

.overSeasInnerContainer {
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url('../../../../assets/overseasoverlay.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.overSeasContent {
  padding-left: 150px;
  padding-right: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.overSeasContent > h1 {
  font-size: 50px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 20px;
}

.overSeasContent > strong {
  font-size: 50px;
  color: #fcc028;
  margin-bottom: 20px;
  text-align: center;
}

.overSeasContent > p {
  font-size: 18px;
  color: #fff;
  text-align: center;
  margin-bottom: 30px;
}

@media screen and (max-width: 1500px) {
  .overSeasContainer {
    height: 500px;
  }
  .overSeasInnerContainer {
    width: 70%;
  }
  .overSeasContent {
    padding-left: 120px;
    padding-right: 250px;
  }
}

@media screen and (max-width: 1200px) {
  .overSeasContainer {
    height: 500px;
  }
  .overSeasInnerContainer {
    width: 75%;
  }
  .overSeasContent {
    padding-left: 100px;
    padding-right: 220px;
  }
}

@media screen and (max-width: 1080px) {
  .overSeasContainer {
    height: 500px;
  }
  .overSeasInnerContainer {
    width: 80%;
  }
  .overSeasContent {
    padding-left: 80px;
    padding-right: 200px;
  }
  .overSeasContent > h1 {
    font-size: 40px;
    text-align: center;
  }
  .overSeasContent > strong {
    font-size: 35px;
  }
  .overSeasContent > p {
    font-size: 12px;
  }
}
@media screen and (max-width: 768px) {
  .overSeasContainer {
    height: 500px;
    background-size: cover;
  }
  .overSeasInnerContainer {
    width: 100%;
    background: #484f6ed5;
  }
  .overSeasContent {
    padding-left: 50px;
    padding-right: 50px;
  }
  .overSeasContent > h1 {
    font-size: 30px;
    text-align: center;
  }
  .overSeasContent > strong {
    font-size: 25px;
  }
  .overSeasContent > p {
    font-size: 10px;
  }
}

@media screen and (max-width: 500px) {
  .overSeasContent {
    padding-left: 20px;
    padding-right: 20px;
  }
  .overSeasInnerContainer {
    width: 100%;
    background: #484f6e27;
  }
}

.card-blog {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

.card-blog:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.container {
  padding: 10px 16px;
  display: flex;
  flex-direction: column;
}

.cards-container {
  display: grid !important;
  grid-template-columns: repeat(3, 1fr) !important;
  grid-gap: 20px !important;
  justify-content: space-between;
  margin: 50px 0px;
}

.blog-info {
  display: flex;
  flex-direction: column;
}
.title {
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 0.5em;
}
.read-more-button {
  padding: 10px 5px;
  width: 12%;
  border: 0;
  background-color: #fcc028;
  font-weight: 600;
}

#btn-blog {
  margin-bottom: 20px;
}

@media screen and (max-width: 1150px) {
  .title {
    font-size: 14px;
  }
  .read-more-button {
    width: 15%;
  }
  .blog-info > span {
    font-size: 12px;
  }
}
@media screen and (max-width: 780px) {
  .cards-container {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}
@media screen and (max-width: 425px) {
  .cards-container {
    grid-template-columns: repeat(1, 1fr) !important;
  }
  #btn-blog {
    width: fit-content;
  }
}

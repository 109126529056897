.features {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  height: 100%;
}

.socialContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 20px;
}

.socialContainer > .socialLeft {
  display: flex;
  align-items: center;
}

.socialContainer > .socialLeft > .newsText {
  font-size: 16px;
  color: #000;
  margin-left: 15px;
  text-transform: uppercase;
}

.socialContainer > .newsLetterInputContainer {
  display: flex;
  width: 40%;
  height: 100%;
}

.socialContainer > .newsLetterInputContainer > .newsLetterInput {
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 0px;
  background-color: #fff;
  padding: 10px;
}

.socialContainer > .newsLetterInputContainer > .newsLetterInput:focus {
  outline: none;
}

.socialContainer > .newsLetterInputContainer > .newsLetterButton {
  height: 100%;
  border: none;
  border-radius: 0px;
  background-color: #ffd200;
  padding: 10px 20px;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
}

.socailLinkContainer {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.socialText {
  text-transform: uppercase;
  margin-right: 10px;
  font-weight: bold;
}
.socialLink {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 100px;
  margin-right: 10px;
  background-color: #fff;
}

.socialLink > img {
  width: 60%;
  height: 60%;
}

@media screen and (max-width: 1200px) {
  .socialContainer {
    padding: 10px;
  }
  .socialContainer > .socialLeft > .newsText {
    font-size: 10px;
  }
  .socialContainer > .newsLetterInputContainer > .newsLetterInput {
    font-size: 10px;
  }
  .socialContainer > .newsLetterInputContainer > .newsLetterButton {
    font-size: 10px;
  }
  .socialText {
    font-size: 10px;
  }
  .socialLink {
    width: 30px;
    height: 30px;
  }
  .socialLink > img {
    width: 40%;
    height: 40%;
  }
  .socialLeft {
    font-size: 10px;
  }
}

@media screen and (max-width: 768px) {
  .socialContainer {
    padding: 6px;
  }
  .socialContainer > .socialLeft > .newsText {
    font-size: 7px;
  }
  .socialContainer > .newsLetterInputContainer > .newsLetterInput {
    font-size: 7px;
  }
  .socialContainer > .newsLetterInputContainer > .newsLetterButton {
    font-size: 7px;
  }
  .socialText {
    font-size: 7px;
  }
  .socialLink {
    width: 25px;
    height: 25px;
  }
  .socialLink > img {
    width: 40%;
    height: 40%;
  }
  .socialLeft {
    font-size: 7px;
  }
}
@media screen and (max-width: 500px) {
  #featureContainer,
  .socialLeft,
  .newsLetterInputContainer {
    display: none !important;
  }
  .socialLink > img {
    width: 40%;
    height: 40%;
  }
  .socialText {
    font-size: 12px;
  }
}

.aboutUsContainer {
  width: 100%;
  display: flex;
  padding-top: 180px;
  padding-bottom: 100px;
  position: relative;
}

.aboutUsHead {
  font-size: 80px;
  margin: 0;
}

.aboutUsSubHead {
  font-size: 35px;
  margin: 0;
  font-weight: lighter;
}
.aboutUsSubHead > strong {
  font-size: 55px;
  font-weight: 400;
}

.aboutUsGuaranteeItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 25px;
}

.aboutUsGuaranteeItem > div {
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid #000;
  border-radius: 5px;
  flex: 1;
}

.aboutUsGuaranteeItem > div:first-child {
  margin-right: 30px;
}

.aboutUsGuaranteeItem > div > img {
  margin-right: 10px;
}

.aboutUsContainer > .left {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.aboutUsContainer > .left > p {
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 38px;
  width: 85%;
}

.aboutUsContainer > .right {
  flex: 1;
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
  right: 0;
}

.aboutCategoryCard {
  transform: rotate(45deg);
  margin-left: 60px;
  height: auto;
}

.aboutCategoryCardRotate {
  background-color: #373f68;
  width: 150px;
  height: 150px;
  margin: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: white;
}

.aboutCategoryCardRotate:hover {
  background-color: #fcc028;
}

.aboutCategoryCardRotate:hover > div > span {
  color: #373f68;
  font-weight: bolder;
}
.aboutCategoryCardRotate:hover > div path {
  fill: #000;
}

.aboutCategoryCardRotate > div {
  transform: rotate(-45deg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.aboutCategoryCardRotate > div > svg {
  width: 40px;
  height: 40px;
}
.aboutCategoryCardRotate > div > svg > path {
  fill: #fff;
}

.aboutCategoryCardRotate > div > span {
  font-size: 12px;
  font-weight: lighter;
  text-transform: uppercase;
  margin-top: 10px;
  text-align: center;
  padding: 0px 5px;
}

@media screen and (max-width: 1550px) {
  .aboutCategoryCard {
    scale: 0.8;
    margin-left: 15px;
  }
}

@media screen and (max-width: 1350px) {
  .aboutUsHead {
    font-size: 70px;
  }
  .aboutUsSubHead {
    font-size: 30px;
  }
  .aboutUsSubHead > strong {
    font-size: 50px;
  }
  .aboutUsContainer {
    padding-top: 150px;
    padding-bottom: 80px;
  }

  .aboutUsContainer > .left > p {
    font-size: 14px;
    margin: 0 0 20px;
  }
  .aboutUsGuaranteeItem {
    margin-top: 10px;
    margin-bottom: 15px;
  }
  .aboutUsGuaranteeItem > div {
    width: 180px;
  }
  .aboutUsGuaranteeItem > div > span {
    font-size: 12px;
  }
  .aboutUsGuaranteeItem > div > img {
    width: 30px;
  }
  .aboutUsContainer > .left > button {
    font-size: 14px;
    width: fit-content !important;
    padding: 10px 30px;
  }
  .aboutUsContainer > .right {
    margin-top: 3%;
  }
}

@media screen and (max-width: 1350px) {
  .aboutUsContainer {
    padding-top: 180px !important;
    padding-bottom: 100px !important;
  }
  .aboutUsHead {
    font-size: 50px;
  }
  .aboutUsSubHead {
    font-size: 20px;
  }
  .aboutUsSubHead > strong {
    font-size: 30px;
  }
  .aboutUsContainer {
    padding-top: 120px;
    padding-bottom: 60px;
  }
  .aboutUsContainer > .left > p {
    font-size: 12px;
    margin: 0 0 30px;
  }
  .aboutUsGuaranteeItem {
    margin-top: 10px;
    margin-bottom: 15px;
  }
  .aboutUsGuaranteeItem > div {
    width: 150px;
  }
  .aboutUsGuaranteeItem > div > span {
    font-size: 10px;
  }
  .aboutUsGuaranteeItem > div > img {
    width: 20px;
  }
  .aboutUsContainer > .left > button {
    font-size: 12px;
    width: fit-content !important;
    padding: 10px 20px;
  }
  .aboutUsContainer > .right {
    margin-top: 0%;
  }
}
@media screen and (max-width: 1080px) {
  .aboutCategoryCard {
    scale: 0.7;
    margin-left: -8px;
  }
}

@media screen and (max-width: 1024px) {
  .aboutUsContainer {
    padding-top: 140px !important;
    padding-bottom: 50px !important;
  }
  .aboutUsContainer > .right {
    display: none;
  }
  .aboutUsContainer > .left {
    width: 100%;
  }
}
@media screen and (max-width: 500px) {
  .aboutUsContainer {
    padding: 30px 0px !important;
  }
  .aboutUsHead {
    font-size: 35px;
  }
  .aboutUsSubHead {
    font-size: 16px;
  }
  .aboutUsSubHead > strong {
    font-size: 20px;
  }
  .aboutUsGuaranteeItem {
    display: none;
  }
  .aboutUsContainer > .left > p {
    font-size: 12px;
    margin: 0 0 20px;
    width: 100%;
  }
}

.footerContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-image: url('../../../assets/footerbackground.png');
  background-position: bottom;
}

.footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  color: white;
  height: 40vh;
}

.footerDetail {
  display: flex;
  margin-right: 60px;
  flex: 2;
  flex-direction: column;
  font-size: 14px;
  font-weight: 600;
  justify-content: space-between;
}
.footerDetail > img {
  width: 200px;
}

.footerLinks {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  flex: 1;
  height: 65%;
  font-weight: 600;
  justify-content: space-between;
  color: white;
  font-size: 14px;
}
.footerHeader {
  font-size: 18px;
  text-transform: uppercase;
}

.footerLinks > a {
  color: white;
  text-decoration: none;
  text-transform: capitalize;
  cursor: pointer;
  user-select: none;
}
.footerCopyWrite {
  font-size: 14px;
  font-weight: 200 !important;
  color: white;
  padding: 30px 150px;
  background-color: black;
}

@media screen and (max-width: 1200px) {
  .footerDetail > img {
    width: 150px;
  }
  .footerDetail > p,
  .footerDetail > span {
    font-size: 12px;
    font-weight: 400;
  }
  .footerLinks {
    font-size: 12px;
  }
  .footerCopyWrite {
    padding: 30px;
  }
  .footerHeader {
    font-size: 14px;
  }
}

@media screen and (max-width: 768px) {
  .footerDetail > img {
    width: 100px;
  }
  .footerDetail > p,
  .footerDetail > span {
    font-size: 10px;
    font-weight: 400;
  }
  .footerLinks {
    font-size: 10px;
  }
  .footerCopyWrite {
    padding: 30px;
  }
  .footerHeader {
    font-size: 12px;
  }
  .footerCopyWrite {
    font-size: 10px;
    padding: 15px;
  }
  .footer {
    height: 50vh;
  }
}

@media screen and (max-width: 480px) {
  .footerDetail {
    display: none;
  }
  #firstFooterLinks {
    display: none;
  }
  .footer {
    height: 30vh;
  }
}

.blogCardContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 10px;
  overflow: hidden;
  background-color: #fff;
  margin-right: 10px;
}

.blogCardHeader {
  position: relative;
  height: fit-content;
}

.blogCardHeader > img {
  width: 100%;
  height: 250px;
  object-fit: contain;
}

.blogCardHeader > div {
  position: absolute;
  height: 70px;
  width: 70px;
  background-color: #fcc028;
  border-bottom-right-radius: 100px;
}

.sideBarContainer {
  position: absolute;
  align-self: center;
  display: flex;
  flex-direction: column;
  background-color: transparent !important;
  width: auto !important;
  height: auto !important;
}

.topBar {
  width: 7px;
  height: 40px;
  background-color: #373f68;
}
.bottomBar {
  width: 7px;
  height: 40px;
  background-color: #fcc028;
}

.blogCardBody {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100px;
  padding-left: 20px;
}

.blogCardHeadText {
  font-size: 24px;
  font-weight: 600;
  color: #000000;
  line-height: 1;
  margin-bottom: 10px;
}

.blogCardDate {
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  line-height: 1;
}
.blogCardBody > p {
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  line-height: 1;
  margin-bottom: 10px;
}

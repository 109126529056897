.customerSaysContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 60vh;
  background-color: #f5f5f5;
  padding: 20px 0px;
  background-image: url('../../../../assets/customer-says-background.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin: 0px;
}

.customerSaysContainer > strong {
  font-size: 50px;
  text-transform: uppercase;
  color: #fcc028;
}

.customerSaysContainer > hr {
  width: 20%;
  border: 2px solid #fcc028;
  margin-top: 10px;
  margin-bottom: 10px;
}

.customerSaysContainer > p {
  width: 75%;
  text-align: justify;
  font-size: 27px;
  color: #fff;
}

@media screen and (max-width: 1080px) {
  .customerSaysContainer > strong {
    font-size: 40px;
  }

  .customerSaysContainer > p {
    width: 80%;
    font-size: 23px;
  }
}

@media screen and (max-width: 800px) {
  .customerSaysContainer {
    display: none;
  }
}

.blogsContainer > strong {
  font-size: 36px;
  color: #fcc028;
  text-transform: uppercase;
}

.blogsContainer > hr {
  width: 3%;
  border: 2px solid #fcc028;
  margin-top: 3px;
  margin-bottom: 50px;
}

.blogsCardContainer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.blogsCardContainer > div:nth-child(2) {
  margin-left: 20px;
  margin-right: 20px;
}

@media screen and (max-width: 500px) {
  .blogsContainer > strong {
    font-size: 30px;
  }
  .blogsContainer > hr {
    width: 10%;
  }
}

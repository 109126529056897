#about-us-img {
  width: 700px;
  height: 350px;
  object-fit: cover;
}
#how-we-work > div > div {
  width: 50%;
}

@media screen and (max-width: 1440px) {
  #about-us-img {
    height: 350px !important;
  }
  #company-history > div > div > span {
    font-size: 14px;
  }
  #company-history-heading {
    font-size: 30px;
  }
  #how-we-work > div > div span {
    font-size: 14px;
  }
}
@media screen and (max-width: 1200px) {
  #about-us-img {
    height: 300px !important;
  }
  #company-history > div > div {
    margin-left: 30px;
  }
  #company-history > div > div span {
    font-size: 12px;
  }
  #company-history-heading {
    font-size: 25px;
  }
  #how-we-work > div > div span {
    font-size: 12px;
  }
}
@media screen and (max-width: 1080px) {
  #about-us-img {
    height: 400px !important;
    margin-top: 30px;
  }
  #company-history > div {
    flex-direction: column-reverse;
  }
  #company-history > div > div {
    margin-left: 0px;
  }
  #company-history > div > div span {
    font-size: 12px;
  }
  #company-history-heading {
    font-size: 20px;
  }
  #how-we-work > div > div span {
    font-size: 10px;
  }
  #how-we-work-btn {
    font-size: 12px !important;
    width: fit-content;
  }
}

@media screen and (max-width: 800px) {
  #about-us-img {
    width: 100%;
    height: auto;
  }
  #company-history {
    padding: 70px;
  }
}
@media screen and (max-width: 768px) {
  #how-we-work {
    padding: 70px;
  }
  #how-we-work > div > img {
    display: none;
  }
  #how-we-work > div > div {
    width: 100%;
    margin: 0;
  }
  #how-we-work-btn {
    font-size: 10px !important;
  }
}
@media screen and (max-width: 630px) {
  #about-us-img {
    width: 100%;
    height: 300px !important;
  }
  #company-history,
  #how-we-work {
    padding: 50px;
  }
}
@media screen and (max-width: 500px) {
  #company-history,
  #how-we-work {
    padding: 30px;
  }
}
@media screen and (max-width: 375px) {
  #about-us-img {
    width: 100%;
    height: 200px !important;
  }
}

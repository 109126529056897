.contactHeroCardContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 20px;
  background-color: white;
  z-index: 1;
  border-radius: 5px;
  flex: 1;
  margin-left: 2rem;
  margin-right: 2rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.contactHeroCardContainer > div {
  background-color: #373f68;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contactHeroCardContainer > div > img {
  width: 30px;
}

.contactHeroCardContainer > strong {
  color: #373f68;
  font-size: 1.4rem;
  font-weight: bold;
  margin: 1rem 0;
  z-index: 1;
}

.contactHeroCardContainer > span {
  color: #373f68;
  font-size: 0.9rem;
  font-weight: bold;
  margin: 0.2rem 0;
  z-index: 1;
}

.contactHeroCardContainer > a {
  background-color: #373f68;
  color: white;
  border: none;
  width: 80%;
  text-decoration: none;
  text-align: center;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 0.9rem;
  font-weight: bold;
  cursor: pointer;
  margin-top: 1rem;
}

@media screen and (max-width: 1320px) {
  .contactHeroCardContainer {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
@media screen and (max-width: 1200px) {
  .contactHeroCardContainer {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    padding: 20px 10px;
  }
  .contactHeroCardContainer > div {
    width: 60px;
    height: 60px;
  }
  .contactHeroCardContainer > div > img {
    width: 20px;
  }
  .contactHeroCardContainer > strong {
    font-size: 1.2rem;
  }
  .contactHeroCardContainer > span {
    font-size: 0.8rem;
  }
  .contactHeroCardContainer > button {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 850px) {
  .contactHeroCardContainer > div {
    width: 40px;
    height: 40px;
  }
  .contactHeroCardContainer > div > img {
    width: 15px;
  }
  .contactHeroCardContainer > strong {
    font-size: 1rem;
  }
  .contactHeroCardContainer > span {
    font-size: 0.7rem;
  }
  .contactHeroCardContainer > button {
    font-size: 0.7rem;
  }
}
@media screen and (max-width: 720px) {
  .contactHeroCardContainer > div {
    width: 40px;
    height: 40px;
  }
  .contactHeroCardContainer > div > img {
    width: 15px;
  }
  .contactHeroCardContainer > strong {
    font-size: 0.9rem;
  }
  .contactHeroCardContainer > span {
    font-size: 0.6rem;
  }
  .contactHeroCardContainer > button {
    font-size: 0.6rem;
  }
}
@media screen and (max-width: 630px) {
  .contactHeroCardContainer {
    margin-left: 0.3rem;
    margin-right: 0.3rem;
    padding: 15px 10px;
  }
  .contactHeroCardContainer > strong {
    font-size: 0.8rem;
  }
  .contactHeroCardContainer > span {
    font-size: 0.5rem;
  }
  .contactHeroCardContainer > button {
    font-size: 0.5rem;
  }
}
@media screen and (max-width: 450px) {
  .contactHeroCardContainer {
    margin: 1rem;
    padding: 15px 10px;
    width: 60%;
  }
  .contactHeroCardContainer > strong {
    font-size: 0.8rem;
  }
  .contactHeroCardContainer > span {
    font-size: 0.5rem;
  }
  .contactHeroCardContainer > button {
    font-size: 0.5rem;
  }
}

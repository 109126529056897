@media screen and (max-width: 1150px) {
  #consult-an-expert-right > p {
    font-size: 14px;
  }
  #consult-an-expert-right > h3 {
    font-size: 16px;
  }
  #request-a-call-back-btn {
    width: fit-content;
  }
}

@media screen and (max-width: 768px) {
  #registration-services {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  #consult-an-expert-right > p {
    font-size: 12px;
  }
  #consult-an-expert-right > h3 {
    font-size: 14px;
  }
  #request-a-call-back-btn {
    width: fit-content;
    font-size: 14px;
  }
}

@media screen and (max-width: 700px) {
  #consult-an-expert-container {
    padding: 70px;
  }
  #consult-an-expert-right {
    margin: 0px !important;
  }
}

@media screen and (max-width: 630px) {
  #consult-an-expert-container {
    padding: 50px;
  }
}

@media screen and (max-width: 500px) {
  #consult-an-expert-container {
    padding: 30px;
  }
  #consult-an-expert-right {
    margin: 0px !important;
  }
}

@media screen and (max-width: 425px) {
  #consult-an-expert-container {
    display: none;
  }
}

#registrationServiceCardMobile {
  display: none;
}

@media screen and (max-width: 425px) {
  #registrationServiceCard {
    display: none;
  }
  #registrationServiceCardMobile {
    display: flex;
    width: 100%;
  }
  #salesTaxContainer {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.clientReviewContainer {
  display: flex;
  flex-direction: column;
}

.clientReviewContainer > span {
  font-size: 20px;
  color: #242f51;
  width: 70%;
  font-weight: bold;
  margin-bottom: 33px;
}

.clientReviewContainer > .reviewComments {
  font-size: 16px;
  font-weight: lighter;
  margin: 0px;
  color: #616368;
}

.reviewClient {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 28px;
}

.reviewClient > img {
  width: 65px;
  height: 65px;
  object-fit: cover;
  border-radius: 100%;
}

.revieClientDetail {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}
.revieClientDetail > .name {
  font-size: 16px;
  font-weight: bold;
  color: #242f51;
}
.revieClientDetail > .desination {
  font-size: 14px;
  color: #616368;
}

.companyIcon {
  width: 7em;
}

@media screen and (max-width: 1024px) {
  .companyIcon {
    width: 6em;
  }
}

@media screen and (max-width: 768px) {
  .companyIcon {
    width: 5em;
  }
}

.sidebarContainer {
  box-shadow: 10px 0px 9px #a5a5a5;
  gap: 0.5em;
  padding: 2em 0;
}

.sidebarContainer > label {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebarContainer > label > img {
  border: 1px solid #fcc028;
  width: 15em;
  height: 15em;
  object-fit: contain;
  border-radius: 100%;
}

.dashboardLabel,
.normalLabel {
  background-color: #fcc028;
  font-weight: 700;
  font-size: 20px;
  margin: 0;
  width: 100%;
  text-align: center;
  color: black;
  text-decoration: none;
  padding: 1em 0;
}
.normalLabel {
  background-color: transparent;
  font-weight: 400;
  font-size: 16px;
  padding: 0.6em 0;
}

.sidebarItem {
  user-select: none;
}

@media screen and (max-width: 720px) {
  .sidebarContainer > label > img {
    width: 10em;
    height: 10em;
    object-fit: contain;
    border-radius: 100%;
  }
}

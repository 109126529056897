#contactPageHeroSection {
  position: relative;
}

#contactPageMap {
  height: 70vh;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
}

@media screen and (max-width: 1080px) {
  #contactPageCardContainer {
    left: 100px;
    right: 100px;
  }
}
@media screen and (max-width: 850px) {
  #contactPageCardContainer {
    left: 70px;
    right: 70px;
  }
}
@media screen and (max-width: 630px) {
  #contactPageCardContainer {
    left: 50px;
    right: 50px;
  }
  #contactPageMap {
    height: 50vh;
  }
}
@media screen and (max-width: 540px) {
  #contactPageCardContainer {
    left: 0px;
    right: 0px;
  }
  #contactPageMap {
    height: 40vh;
  }
}
@media screen and (max-width: 450px) {
  #contactPageCardContainer {
    position: unset;
  }
  #contactPageCardContainer > div {
    flex-direction: column;
    align-items: center;
  }
  #contactPageMap {
    height: 30vh;
  }
}

.card_insight {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  overflow: hidden;
  padding-bottom: 10px;
}

.card_insight_container {
  padding: 10px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
}

.description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.navItemsContainer {
  display: flex;
}
.navBtn {
  padding: 5px 10px;
  font-weight: bold;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 1em;
  cursor: pointer;
  color: white;
  text-decoration: none;
  user-select: none;
  text-transform: capitalize;
}
.navBtn:hover {
  background-color: #fcc028;
  border-radius: 5px;
  color: #373f68;
}

#menuIcon {
  display: none;
}
#logoHead > img {
  width: 200px;
}

@media (max-width: 1440px) {
  .navBtn {
    font-size: 0.9em;
  }
  #appointmentBtn {
    font-size: 0.9em !important;
  }
  #logoHead > img {
    width: 170px;
  }
}

@media screen and (max-width: 1080px) {
  .navBtn {
    font-size: 9px;
    padding: 5px 8px;
  }
  #appointmentBtn {
    font-size: 9px !important;
  }
  #logoHead > img {
    width: 150px;
  }
}

@media screen and (max-width: 940px) {
  .navBtn {
    font-size: 8px;
    padding: 5px 6px;
  }
  #appointmentBtn {
    font-size: 8px !important;
  }
  #logoHead > img {
    width: 120px;
  }
}

@media screen and (max-width: 860px) {
  .navBtn {
    font-size: 7px;
    padding: 5px 4px;
  }
  #appointmentBtn {
    font-size: 7px !important;
  }
  #logoHead > img {
    width: 100px;
  }
}

@media screen and (max-width: 740px) {
  .navBtn {
    padding: 5px 3px;
  }
  #logoHead > img {
    width: 80px;
  }
}

@media screen and (max-width: 550px) {
  .navItemsContainer {
    flex-direction: column;
    position: absolute;
    background-color: #373f68;
    width: 94%;
    padding: 15px;
    z-index: 10;
    top: 80px;
  }
  .navBtn {
    font-size: 10px;
    width: 100%;
  }
  #appointmentBtn {
    display: none;
  }
  #logoHead > img {
    width: 150px;
  }
  #logoHead {
    align-self: center;
  }
  #navHeaderContainer {
    justify-content: center !important;
  }
  #menuIcon {
    display: block;
    position: absolute;
    left: 30px;
    font-size: 1.5em;
  }
}

.detailContainer {
  width: 40%;
  background-color: #373f688f;
  height: 100vh;
  display: flex;
  padding-right: 30px;
  flex-direction: column;
  color: white;
  justify-content: center;
  padding-left: 150px;
  z-index: 1;
}
.sliderBtn {
  position: absolute;
  z-index: 100;
  cursor: pointer;
  top: 50%;
  border-radius: 100%;
  background-color: #fff;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 60%;
}

.sliderBtn:hover {
  background-color: #373f688f;
}

.detailContainer > strong {
  font-size: 4rem;
  text-transform: capitalize;
}
.detailContainer > p {
  font-size: 1.5rem;
}

.detailContainer > button {
  width: 150px;
  font-weight: bolder;
  font-size: unset;
}

.heroCardContainer {
  display: flex;
  width: 80%;
  align-self: center;
  justify-content: flex-end;
  background-color: transparent;
  position: absolute;
  bottom: -150px;
}

.cardLogoSvg {
  padding: 15px;
  background: #373f68;
  border-radius: 15px;
  height: 2em;
  width: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cardLogoSvg svg {
  width: 2em;
  height: 2em;
}
.cardLogoSvg path {
  fill: #fff !important;
}

@media screen and (max-width: 1550px) {
  .detailContainer {
    padding-right: 20px;
    padding-left: 120px;
  }
  .detailContainer > strong {
    font-size: 3.5rem;
  }
  .detailContainer > p {
    font-size: 1.2rem;
  }
  .detailContainer > button {
    padding: 10px;
    font-size: 14px;
    width: 150px;
  }
  .heroCardContainer {
    width: 80%;
  }
  .heroCardContainer > div {
    font-size: 14px;
  }
  .cardLogoSvg {
    height: 2em;
    width: 2em;
  }
  .cardLogoSvg svg {
    width: 2em;
    height: 2em;
  }
}

@media screen and (max-width: 1200px) {
  .detailContainer {
    padding-right: 20px;
    padding-left: 100px;
  }
  .detailContainer > strong {
    font-size: 3rem;
  }
  .detailContainer > p {
    font-size: 1rem;
  }
  .detailContainer > button {
    padding: 10px;
    font-size: 12px;
    width: 150px;
  }
  .heroCardContainer {
    width: 80%;
  }
  .heroCardContainer > div {
    font-size: 12px;
    margin: 0px !important;
  }
}

@media screen and (max-width: 800px) {
  .heroContainer,
  .detailContainer,
  .heroBackground {
    background-position: center;
    min-height: 60vh !important;
    height: 60vh !important;
  }
  .detailContainer {
    padding-right: 20px;
    padding-left: 70px;
  }
  .heroCardContainer {
    width: 90%;
    bottom: -120px;
  }
  .heroCardContainer > .card {
    width: 100%;
  }

  .heroCardContainer > div {
    font-size: 10px;
    margin: 0 0 0 0 !important;
  }
  .heroCardContainer > div img {
    width: 16px !important;
    height: 16px !important;
  }
  .detailContainer > strong {
    font-size: 2rem;
  }
  .detailContainer > p {
    font-size: 10px;
  }
  .detailContainer > button {
    padding: 10px;
    font-size: 10px;
    width: 150px;
  }
  #statusRulesUpdatesCard {
    height: 300px !important;
  }
  .cardHero {
    height: fit-content !important;
  }
  .cardLogoSvg {
    padding: 10px;
    height: 2.5em;
    width: 2.5em;
  }
  .cardLogoSvg svg {
    width: auto;
    height: auto;
  }
}

@media screen and (max-width: 650px) {
  .detailContainer > strong {
    font-size: 2rem;
  }
  .detailContainer > span {
    font-size: 25px;
  }
  .detailContainer > p {
    font-size: 7px;
  }
  .detailContainer > button {
    padding: 8px 15px;
    font-size: 10px;
    width: fit-content !important;
  }

  .heroCardContainer > div img {
    width: 14px !important;
    height: 14px !important;
  }
}

@media screen and (max-width: 500px) {
  .heroCardContainer {
    display: none;
  }
  .heroContainer,
  .detailContainer,
  .heroBackground {
    min-height: 320px !important  ;
    height: 320px;
  }
  .detailContainer {
    width: 50%;
    padding: 30px 30px;
  }
  .detailContainer > strong {
    font-size: 1.5rem;
  }
}

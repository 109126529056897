.view-more-button {
  padding: 10px 5px;
  width: 32%;
  border: 0;
  background-color: #fcc028;
  font-weight: 600;
  cursor: "pointer";
}

.emailForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.inputField {
  width: 95%;
  border: none;
  background-color: #fff;
  font-size: medium;
  padding: 10px;
  border-radius: 3px;
  margin-bottom: 27px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2);
}
.email-form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
  padding: 10px 20px;
  background-color: #fcc028;
}

.email-form-container > form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.email-form-container > form > input,
.email-form-container > form > textarea {
  width: 95%;
  border: none;
  background-color: #fff;
  font-size: medium;
  padding: 10px;
  border-radius: 3px;
  margin-bottom: 27px;
}

.email-form-container > form > textarea {
  height: 100px;
}

.send-button {
  background-color: #373f68;
  color: white;
  border: none;
  width: 60%;
  border-radius: 25px;
  padding: 10px 20px;
  font-size: 0.9rem;
  font-weight: bold;
  cursor: pointer;
  margin-top: 1rem;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2);
}
@media screen AND (max-width: 375px) {
  .card {
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    width: 45%;
    margin: 5% 0;
  }
  .view-more-button {
    padding: 10px 5px;
    width: 32%;
    border: 0;
    background-color: #fcc028;
    font-weight: 600;
    cursor: "pointer";
  }
}

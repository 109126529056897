.clientStatisfactionContainer {
  width: 100%;
  height: 800px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.clientStatisfactionLeft {
  flex: 1;
  height: 100%;
  position: relative;
}

.clientStatisfactionLeft::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background-image: url('../../../../assets/map.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 100%;
  opacity: 0.2;
}

.clientStatisfactionRight {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.clientStatisfactionRight > strong {
  font-size: 40px;
  color: #242f51;
  width: 70%;
}

.clientStatisfactionRight > span {
  font-size: 16px;
  color: #616368;
  font-weight: bold;
  width: 70%;
  margin-top: 30px;
}

.clientReviews {
  position: relative;
}

.clientReviewsBtn {
  display: flex;
  position: absolute;
  bottom: 20px;
  right: 0px;
  width: 100px;
}

.clientReviewsBtn > div {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 800px) {
  .clientStatisfactionLeft {
    display: none;
  }
  .clientStatisfactionRight > strong {
    display: none;
  }
  .clientStatisfactionRight > span {
    display: none;
  }
  .clientStatisfactionContainer {
    height: fit-content;
    padding: 40px 0px;
  }
}

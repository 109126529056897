.taxAndLegalContainer {
  width: 100%;
  display: flex;
  margin-top: -10px;
  flex-direction: column;
  align-items: center;
  background-image: url('../../../../assets/taxandlegalbackground.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-top: 60px;
  padding-bottom: 60px;
  color: #fff;
}

.taxAndLegalContainer > strong {
  font-size: 50px;
  text-transform: uppercase;
}

.taxAndLegalContainer > hr {
  width: 5%;
  border: 3px solid #f2622b;
  margin-top: 10px;
  margin-bottom: 10px;
}

.taxAndLegalContainer > p {
  width: 60%;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
}

.taxAndLegalDataGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 70%;
  grid-row-gap: 15px;
  grid-column-gap: 80px;
  margin-top: 30px;
}

@media screen and (max-width: 930px) {
  .taxAndLegalDataGrid {
    width: 80%;
  }
}
@media screen and (max-width: 800px) {
  .taxAndLegalContainer {
    display: none;
  }
}

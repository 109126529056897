.taxCardContainer {
  padding: 10px;
  border-bottom: 1px solid #fff;
}

.taxCardContainer > strong {
  font-size: 24px;
  text-transform: capitalize;
  color: #fcc028;
}

.taxCardContainer > p {
  font-size: 14px;
  font-weight: 200;
}

.taxCardReadMoreBtn > span {
  font-weight: bold;
  cursor: pointer;
}

.taxCardReadMoreBtn > img {
  filter: invert(100%) contrast(200%) brightness(200%);
}

.calculatorContainer {
  display: flex;
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
  padding: 25px;
}
.calculatorContainer > div:first-child {
  flex: 1;
}
.calculatorContainer > div:last-child {
  flex: 2;
}
.calculatorContainer > div > p {
  text-align: justify;
}
.calculatorFormContainer {
  margin: 10px;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
}

.calculatedDetail {
  display: flex;
  flex-direction: column;
  margin-top: 2.3rem;
}

.calculatedDetail > div {
  display: flex;
}

.calculatedDetail > div:nth-child(2) {
  border-bottom: 3px solid rgb(41, 38, 38);
}

.calculatedDetail > div > span {
  padding: 15px;
  flex: 1;
}

.calculatedDetail > div > h2 {
  padding: 15px;
  margin: 0px;
  flex: 1;
}

.calculatedDetail > div > span:nth-child(2) {
  border-left: 3px solid rgb(41, 38, 38);
  border-right: 3px solid rgb(41, 38, 38);
}

.calculatedDetail > div > h2:nth-child(2) {
  border-left: 3px solid rgb(41, 38, 38);
  border-right: 3px solid rgb(41, 38, 38);
}

@media screen and (min-width: 720px) {
  #saleTaxCalculator {
    padding: 0px 0px;
  }
  .saleTaxCalculator {
    padding: 5px;
  }
}

@media screen and (max-width: 800px) {
  #service,
  #team-container {
    padding: 70px;
  }
}
@media screen and (max-width: 630px) {
  #service,
  #team-container {
    padding: 50px;
  }
}
@media screen and (max-width: 500px) {
  #service,
  #team-container {
    padding: 30px;
  }
}
@media screen and (max-width: 375px) {
  #libraryCardContainer {
    grid-template-columns: 1fr !important;
  }
  #libraryDashboardHeader {
    padding-top: 2em;
    padding-bottom: 2em;
  }
}
.sliderTeam {
  display: flex !important;
  flex-direction: row !important;
  width: 100% !important;
  justify-content: center;
}

html {
  scroll-behavior: smooth;
}

@media print {
  .content {
    width: 100%;
  }
  .sideBar {
    display: none !important;
  }
  #finalCalculatorContainer {
    padding: 0px;
  }
  #navHeaderContainer,
  #topLevelNavBar,
  .footerContainer {
    display: none !important;
  }
}

.fileIcon {
  width: 200px;
  height: 200px;
}

.featuresCardContainer {
  display: flex;
  flex-direction: column;
  padding: 30px 5px;
  flex: 1;
  border-left: 1px solid #f5f5f5;
  border-right: 1px solid #f5f5f5;
  border-radius: 5px;
  align-items: center;
  height: auto;
}

.featuresCardContainer > img {
  width: 50px;
  height: auto;
}

.featuresCardContainer > strong {
  color: #222222;
  font-size: 15px;
  margin-top: 20px;
  text-transform: uppercase;
}

.featuresCardContainer > p {
  color: #999999;
  font-size: 12px;
  text-align: center;
}

@media screen and (max-width: 1200px) {
  .featuresCardContainer > img {
    width: 40px;
  }
  .featuresCardContainer > strong {
    font-size: 12px;
  }
  .featuresCardContainer > p {
    font-size: 10px;
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  .featuresCardContainer > img {
    width: 30px;
  }
  .featuresCardContainer > strong {
    font-size: 10px;
    text-align: center;
  }
  .featuresCardContainer > p {
    font-size: 8px;
  }
}

.getInTouchContainer {
  position: absolute;
  display: flex;
  top: 280px;
  left: 270px;
  right: 270px;
  bottom: -40px;
  background-color: #fff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  padding: 30px 40px;
}

.getInTouchRight {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #373f68;
  padding: 30px 20px;
  flex: 1;
  position: absolute;
  left: 50px;
  top: -20px;
  bottom: -20px;
  border-radius: 3px;
  box-shadow: 0px 0px 5px 0px #373f68;
}

.getInTouchRight > strong {
  color: #ff8e02;
  font-size: 48px;
  font-weight: lighter;
  text-transform: capitalize;
  margin-bottom: 51px;
}

.getInTouchRight > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.getInTouchRight > div > input,
.getInTouchRight > div > textarea {
  width: 95%;
  border: none;
  background-color: #fff;
  font-size: medium;
  padding: 10px;
  border-radius: 3px;
  margin-bottom: 27px;
}

.getInTouchRight > div > textarea {
  height: 100px;
}

.getInTouchLeft {
  display: flex;
  flex-direction: column;
  flex: 2;
  justify-content: space-between;
  align-items: center;
  margin-left: 450px;
  margin-right: 50px;
}

.getInTouchLeft > strong {
  font-size: 48px;
}

.getInTouchLeft > strong:nth-child(1) {
  align-self: flex-start;
}

.getInTouchLeft > strong:nth-child(2) {
  margin-top: 40px;
}

.getInTouchLeft > p {
  font-size: 20px;
  margin-bottom: 20px;
  text-align: justify;
}

.foundUsContainer {
  display: flex;
}

.foundUsContainer > .socialLink {
  padding: 10px;
  background-color: #fcc028;
  margin-top: 15px;
}

@media screen and (max-width: 1550px) {
  .getInTouchLeft {
    margin-right: 10px;
    justify-content: center;
  }
  .getInTouchLeft > strong {
    font-size: 36px;
  }
  .getInTouchLeft > p {
    font-size: 16px;
  }
  .getInTouchLeft > strong:nth-child(2) {
    margin-top: 20px;
  }
  #getInTouchBtn {
    font-size: 20px;
  }
}

@media screen and (max-width: 1400px) {
  #getInTouchBtn {
    font-size: 16px;
  }
}

@media screen and (max-width: 1360px) {
  .getInTouchContainer {
    left: 200px;
    right: 200px;
  }
}

@media screen and (max-width: 1200px) {
  .getInTouchLeft {
    margin-left: 350px;
  }
  .getInTouchContainer {
    left: 170px;
    right: 170px;
  }
  .getInTouchLeft > strong {
    font-size: 30px;
  }
  .getInTouchRight > strong {
    font-size: 30px;
  }

  .getInTouchLeft > p {
    font-size: 14px;
  }
}

@media screen and (max-width: 1100px) {
  #getInTouchBtn {
    font-size: 14px;
  }
}

@media screen and (max-width: 1024px) {
  .getInTouchContainer {
    left: 130px;
    right: 130px;
  }
  .getInTouchRight {
    left: 35px;
  }
  .getInTouchLeft {
    margin-left: 320px;
  }
}
@media screen and (max-width: 900px) {
  .getInTouchContainer {
    left: 100px;
    right: 100px;
  }
  .getInTouchRight {
    left: 30px;
  }
  .getInTouchLeft {
    margin-left: 320px;
  }
  .getInTouchLeft > strong {
    font-size: 25px;
  }
  .getInTouchRight > strong {
    font-size: 25px;
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 800px) {
  .getInTouchContainer {
    left: 70px;
    right: 70px;
  }
  .getInTouchRight {
    left: 30px;
  }
  .getInTouchLeft {
    margin-left: 250px;
  }
  .getInTouchLeft > strong {
    font-size: 20px;
  }
  .getInTouchLeft > p {
    font-size: 12px;
  }
  #getInTouchBtn,
  #getInTouchMessage {
    font-size: 12px;
    width: fit-content !important;
  }
  .getInTouchRight > strong {
    font-size: 20px;
    margin-bottom: 30px;
  }
  .getInTouchRight {
    height: fit-content;
    width: 200px;
  }
  .getInTouchRight > form {
    width: 100%;
  }
  .getInTouchRight > form > textarea {
    height: 70px;
  }
  #getInTouch {
    height: 510px;
  }
  .getInTouchRight > form > input,
  .getInTouchRight > form > textarea {
    margin-bottom: 15px;
    font-size: 12px;
  }
}
@media screen and (max-width: 670px) {
  .getInTouchLeft {
    display: none;
  }
  .getInTouchRight {
    position: unset;
    margin-top: 150px;
    width: 70% !important;
  }
  .getInTouchContainer {
    position: unset;
    box-shadow: none;
  }
  #getInTouch {
    height: fit-content;
  }
}
@media screen and (max-width: 450px) {
  .getInTouchContainer {
    padding: 0px;
    margin-bottom: 20px;
  }
  .getInTouchRight {
    margin-top: 0px;
  }
}

.tLHInfoContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  user-select: none;
  font-size: 12px;
}

.TLHInfo {
  text-decoration: none;
  color: white;
  margin-left: 5px;
  margin-right: 5px;
}

.tLHButtonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  user-select: none;
  font-size: 12px;
}
.tLHButtonContainer > span,
.tLHButtonContainer > a {
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
  padding: 5px 10px;
  color: white;
  text-decoration: none;
  text-transform: capitalize;
}

.tLHButtonContainer > span:hover,
.tLHButtonContainer > a:hover {
  background-color: #fcc028;
  border-radius: 5px;
  color: #373f68;
}

@media screen and (max-width: 860px) {
  .tLHButtonContainer {
    font-size: 0.7em !important;
    padding: 5px 8px;
  }
  .tLHInfoContainer {
    font-size: 0.7em !important;
  }
}

@media screen and (max-width: 550px) {
  .tLHInfoContainer {
    flex-direction: column;
    font-size: 0.5em !important;
  }
  .tLHButtonContainer {
    font-size: 0.5em !important;
  }
}

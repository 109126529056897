#finalCalculatorContainer {
  table {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  table td,
  table th {
    border: 1px solid #ddd;
    padding: 8px;
  }

  table tr:nth-child(even) {
    background-color: #feeeca;
  }

  table tr:hover {
    background-color: #feeeca;
  }

  tbody {
    td:last-child {
      text-align: center;
    }
  }

  table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #373e68;
    color: white;
  }

  .totalAmount {
    text-align: center;
    margin: 0px;
    padding: 1rem;
    text-transform: uppercase;
  }
  input {
    padding: 1em;
    width: -webkit-fill-available;
  }
  h3 {
    text-transform: uppercase;
  }
  button {
    padding: 1rem;
    border: 0;
    width: fit-content;
    margin: auto;
    font-weight: 600;
    font-size: 16px;
    border-radius: 10px;
    box-shadow: 1px 1px 1px 1px #dddd;
    @media print {
      display: none;
    }
  }
}

.provideContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0px;
  margin: 0px;
}

.topBanner {
  color: white;
  padding: 30px 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.topBanner > strong {
  font-size: 50px;
}

.privideInnerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
  margin: 0px;
}

.privideInnerContainer > strong {
  font-size: 72px;
  color: #194173;
  border-bottom: 4px solid #194173;
}

.privideInnerContainer > p {
  font-size: 18px;
  color: #000;
  font-weight: 600;
  text-align: center;
  width: 70%;
}

.provideCardContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
  margin: 0px;
}

.provideCardContainer > div:nth-child(2) {
  margin-left: 40px;
  margin-right: 40px;
}

@media screen and (max-width: 1440px) {
  .topBanner > strong {
    font-size: 40px;
  }
  .topBanner {
    padding: 20px 0px;
  }
}
@media screen and (max-width: 1100px) {
  .privideInnerContainer > strong {
    font-size: 50px;
  }
}
@media screen and (max-width: 980px) {
  .topBanner > strong {
    font-size: 30px;
  }
  .topBanner {
    padding: 20px 0px;
  }
  .topBanner > button {
    padding: 1em 3em;
    width: fit-content !important;
  }

  .provideCardContainer > div:nth-child(2) {
    margin-left: 20px;
    margin-right: 20px;
  }
  .privideInnerContainer > strong {
    font-size: 50px;
  }
  .privideInnerContainer > p {
    font-size: 16px !important;
    width: 90%;
  }
  .provideCardContainer > div > span {
    font-size: 20px !important;
    text-align: center;
  }
  .provideCardContainer > div > img {
    width: 50px;
    height: 50px;
  }
  .provideCardContainer > div > p {
    font-size: 12px !important;
  }
}

@media screen and (max-width: 830px) {
  .privideInnerContainer > strong {
    font-size: 40px;
  }
  .privideInnerContainer > p {
    font-size: 12px !important;
    width: 90%;
  }
  .topBanner > strong {
    font-size: 24px;
  }
  .topBanner {
    padding: 20px 0px;
  }
  .topBanner > button {
    padding: 0.5em 2.5em;
    width: fit-content !important;
    font-size: 16px;
  }
  .privideInnerContainer {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .privideInnerContainer > strong {
    font-size: 48px;
    text-align: center;
  }
  .privideInnerContainer > p {
    font-size: 14px;
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .topBanner > strong {
    font-size: 20px;
  }
  .topBanner {
    padding: 20px 0px;
  }
  .topBanner > button {
    padding: 0.5em 1.5em;
    width: fit-content !important;
    font-size: 14px;
  }
  .privideInnerContainer {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .privideInnerContainer > strong {
    font-size: 35px;
    text-align: center;
  }
  .privideInnerContainer > p {
    font-size: 12px;
    width: 100%;
  }

  .provideCardContainer > div {
    display: none;
  }
  .provideCardContainer > div:first-child {
    display: flex;
  }
}

@media screen and (max-width: 380px) {
  .topBanner > strong {
    font-size: 16px;
  }
  .topBanner > button {
    width: fit-content !important;
    padding: 0.5em 0.8em;
    font-size: 12px;
  }
  .privideInnerContainer > strong {
    font-size: 30px;
    text-align: center;
  }
}
